import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import {LaganEngineeringTestimonial} from '../components/testimonial';

import rotaPlannerMobileSrc from '../images/rota-planner-mobile-app.png';
import rotaPlannerSrc from '../images/rota-planner.png';
import rotaPlannerDragDropSrc from '../images/rota-planner-drag-and-drop.gif';
import tabletMultipleEmployeesSrc from '../images/kiosk-keypad.png';


import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'

import FreeTrialButton from '../components/free-trial-button';

import AppStoreIcons from '../components/app-store-icons';


const RotaPlanner = () => (
  <Layout>
    <SEO 
    	title="Rota Planner for Shift Employees in the UK"
    	description="Plan your staff rota online and keep your staff scheduled on the right shifts. With Mobile App."
    	 />
    <Navbar />
    <div className="bg-gray-50">
	  	<div className="relative bg-white overflow-hidden">
		  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
		    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
		      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
		        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
		          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
		            Ditch your manual
		            <br />
		            <span className="text-highlight-600">staff rota</span> today
		          </h2>
		          
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		            Still using manual staff rotas in {new Date().getFullYear()}? Maybe you are tired of answering questions
		            like "When am I working this week?" or making changes to a messy print out rota. 
		          </p>
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		            With TimeKeeper, you can plan your staff rota online while employees can check when they are working at anytime.
		          </p>
		          <FreeTrialButton />
		        </div>
		        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
		          <div className="relative mx-auto w-full rounded-lg">
		            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
		              <img className="w-full"  src={rotaPlannerSrc} alt="Rota Planner for Multiple Employees with leave unavailability" />
		            </button>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		<div className="py-8 bg-gray-50 overflow-hidden lg:py-6">
		  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				<RightFeature 
			    	sectionTitle="View upcoming shifts" 
			    	title="Employees know when they are expected to work" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          Although a manual shift rota is easy to change, it's very difficult to keep employees up to date when they are expected to attend work.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          With TimeKeeper, employees can view their upcoming shifts at any time on the TimeKeeper mobile app.
					        </FeaturePararaph>
					        <FeaturePararaph>
					         Additionally, any time a change is made to that employees' schedule, we will send them a push notification to keep them updated.
					        </FeaturePararaph>
					        <AppStoreIcons />
					    </div>
			    	}
			    	imgSrc={rotaPlannerMobileSrc}
			    	imgAlt="View upcoming employee shifts on a mobile app"
			   	/>
				<LeftFeature 
			    	sectionTitle="Simple Intuitive UX" 
			    	title="Easy to learn and simple to schedule" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          The rota planner has been designed to make common everyday tasks a breeze.
					          For example, if you need to change a shift to another employee, simply drag that shift across to the right employee!
					        </FeaturePararaph>
					        <FeaturePararaph>
					         Perhaps an employee is booked off on leave? Well in TimeKeeper we highlight any days they are off so you know that they aren't available.
					        </FeaturePararaph>
					        <FeaturePararaph>
					         Or maybe you want to start with last week's rota? Well simply click a button to copy from one week to another in an instant.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={rotaPlannerDragDropSrc}
			    	imgAlt="Drag and drop employee shifts to schedule for multiple employees"
			   	/>
				<RightFeature 
				    	sectionTitle="All-in-One platform" 
				    	title="Rota + Timesheets + Leave = ♡ " 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Rota planning is only the start, once they are scheduled to work, how do you know they turned up on time and how long each employee has worked?
						        </FeaturePararaph>
						        <FeaturePararaph>
						          Luckily TimeKeeper has all the other features you might need including an employee time clock, tracking employee leave, managing employee documents and much more.
						          The best thing being that this all comes in one simple price per employee for all features, what's not to love!
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={tabletMultipleEmployeesSrc}
				    	imgAlt="Employee timesheets, leave and more for shift based workers"
				    	 />

			</div>
			<LaganEngineeringTestimonial />
			<FreeTrial includeDemo={true} part1={<span>Start your first paperless <br /> <span className="text-highlight-600">shift rota</span> now!</span>} />
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default RotaPlanner;
